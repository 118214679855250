<template>
  <div class="req-temp-container">
    <vs-card class="mt-6" v-if="isPayLater">
      <div slot="header">
        <div class="flex items-center">
          <h3 class="font-normal text-xl md:text-2xl">Products</h3>
        </div>
      </div>
      <div>
        <vs-row class="grid md:grid-cols-2 lg:grid-cols-3 gap-x-6">
          <vs-col v-for="(plan, index) in products" :key="index" class="flex">
            <vs-card class="product-card shadow-none">
              <div slot="header">
                <div class="items-center">
                  <h5 class="plan-name mb-2 font-normal">
                    {{ plan.productName }}
                  </h5>
                  <p>
                    <span class="plan-text">Plan ID: {{ plan.customPlanId ? plan.customPlanId : plan.planId }}</span>
                  </p>
                </div>
              </div>
              <div>
                <p class="block pb-6 plan-text">
                  {{ plan.planId.productDescription }}
                </p>
                <div class="flex mb-6">
                  <div class="w-full">
                    <label class="plan-text">{{ plan.planId.feeModel === "MSF_INCLUDED" ? "MSF to merchant (MSF-M)" : "MSF to customer (MSF-C)" }}</label>
                    <div class="flex mt-2">
                      <span class="text-base plan-text">Service fee :</span><span class="pl-5 text-base text-black font-light">{{ plan.msf }} %</span>
                    </div>
                  </div>
                </div>
                <div class="flex mt-3">
                  <div class="w-full">
                    <label class="plan-text mb-2">Display name</label>
                    <vs-input name="displayName" id="displayName" v-model="plan.displayName" class="displayName" v-validate="'max:20'" @input="checkForm"></vs-input>
                    <span class="text-danger text-sm pt-2" v-show="errors.has('displayName')">{{ errors.first("displayName") }}</span>
                  </div>
                </div>
                <vs-button v-round class="w-full only-border-btn mb-4 mt-6 md:mt-8 lg:mt-10 medium" @click="openPlanConfigurationPopup(plan)">View Plan Configuration</vs-button>
              </div>
            </vs-card>
          </vs-col>
        </vs-row>
      </div>
      <vs-row class="block">
        <div class="button-section mb-4 mt-6 md:mt-8">
          <vs-button class="primary lg:mr-6 mr-4" @click="updateDisplayName()" size="large" v-if="isPayLater" v-round>Save</vs-button>
          <a class="mid-blue text-button text-sm pl-1" @click="$router.push({ name: 'settings' })">Cancel</a>
        </div>
      </vs-row>
    </vs-card>
    <vs-popup id="holamundo" class="config-popup paymentSummaryPopup" :title="`${activePlan.productName} configuration`" :active.sync="popupActivo" @close="closePopup">
      <div class="w-full" v-if="activePlan.productType == 'B2C_BNPL'">
        <vs-col vs-w="12">
          <vs-row class="flex justify-between pay-later-card lg:p-4 md:p-3 p-2 items-start gray">
            <div class="flex flex-col justify-center items-center">
              <img :src="update" alt="update" />
              <span class="dark-blue text-xs mt-2"> Every</span>
              <span class="dark-blue text-xs">{{ `${this.everyNumber} ${this.weekOrMonthOrDay}` }}</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="playCircle" alt="playcircle" /><span class="dark-blue text-xs mt-2">Starts on</span>
              <span class="dark-blue text-xs">acceptance date</span>
            </div>
            <div class="flex flex-col justify-center items-center">
              <img :src="stopCircle" alt="stopcircle" />
              <span class="dark-blue text-xs mt-2"> Ends after</span>
              <span class="dark-blue text-xs">{{ `${this.activePlan.noOfPayments} payments` }}</span>
            </div>
          </vs-row>
          <vs-table :data="paymentSchedules" class="payment-schedule-table mt-3">
            <template slot="thead">
              <vs-th class="text-base"> Payment date</vs-th>
              <vs-th class="text-base"> Amount</vs-th>
            </template>
            <template slot-scope="{ data }">
              <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                <vs-td :data="data[indextr].value">
                  {{ formatScheduleDate(data[indextr].value) == formatScheduleDate(new Date()) ? "Today" : formatScheduleDate(data[indextr].value) }}
                </vs-td>
                <vs-td class="sec-row-payment" :data="data[indextr].label">
                  {{ data[indextr].label !== "0.00" ? `$ ${data[indextr].label}` : "-" }}
                </vs-td>
              </vs-tr>
            </template>
          </vs-table>
          <div class="vs-table--content">
          <vs-row class="flex justify-between">
            <span class="text-lg dark-blue">Customer pays</span>
            <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
          </vs-row>
          <vs-row class="mt-4">
            <span class="text-lg dark-blue">Merchant receives</span>
            <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
          </vs-row>
          </div>
        </vs-col>
      </div>

      <div class="w-full" v-if="['B2C_BNPO', 'B2C_BNPO_NC'].includes(activePlan.productType)">
        <vs-col vs-w="12">
          <div class="gray mb-5 items-center flex">
            <div><img :src="calenderImage" alt="calenderImage" /></div>
            <p class="dark-blue text-base font-normal ml-4">
              Proceeds will be deducted on the
              {{ activePlan.initialPeriod }} days from the acceptance date
            </p>
          </div>
          <div class="b2c">
            <vs-row class="flex justify-between">
              <span class="text-lg dark-blue">Customer pays</span>
              <span class="text-lg amount">{{ this.totalCustomerPayable ? `$ ${addZeroes(this.totalCustomerPayable)}` : "-" }}</span>
            </vs-row>
            <vs-row class="mt-4">
              <span class="text-lg dark-blue">Merchant receives</span>
              <span class="text-lg amount">{{ this.merchantAmount ? `$ ${addZeroes(this.merchantAmount)}` : "-" }}</span>
            </vs-row>
          </div>
        </vs-col>
      </div>
    </vs-popup>

    <leave-popup :popup-active="popupActive" @handleClick="popActions"></leave-popup>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import moment from "moment";
const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const calenderImage = require("@/assets/images/calender.png");
import LeavePopup from "@/views/components/LeavePopup";
import { leavePopUpMixin } from "@/mixins/leave-pop-up";

export default {
  mixins: [leavePopUpMixin],
  components: { LeavePopup },
  name: "ProductList",
  data() {
    return {
      products: [],
      activePlan: {},
      popupActivo: false,
      weekOrMonthOrDay: "",
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      calenderImage: calenderImage,
      paymentSchedules: [],
      totalCustomerPayable: "",
      merchantAmount: "",
      everyNumber: "1",
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
      amount: "1000",
      isSaved: false,
      nextObj: "", // determines the next route,
      popupActive: false, // determines if the leave popup is to be shown
      checkFormDirty: false,
      isPayLater: false,
    };
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },
    isValidField() {
      let status = false;

      this.products.map((item) => {
        if (item.displayName === "") {
          status = true;
        }
      });

      return status;
    },
  },
  methods: {
    ...mapActions("paymentPlans", ["calculateOnPayLater", "updatePartnerPlans"]),
    ...mapActions("partner", ["fetchPartnerDetailById"]),
    ...mapActions("admin", ["checkLogin"]),

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    formatScheduleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    async getAllMerchantProducts() {
      this.$vs.loading();
      let partnerId = this.user.userType === 'admin' ? this.user._id : this.user.partnerId;

      await this.fetchPartnerDetailById(partnerId).then((response) => {
        this.products = response.data.data.payLaterPlans.map((el) => {
          if (el.displayName === "") {
            el.displayName = el.productName;
          }
          return el;
        });

        this.isPayLater = response.data.data.requestOptions.includes('pay-later')
        this.$vs.loading.close();
      }).catch((ex) => {
        this.$vs.loading.close();
          this.$vs.notify({
          title: "Products",
          text: ex.response.data.message,
          iconPack: "feather",
          icon: "icon-times-circle",
          color: "danger",
          position: "top-right",
        });

        if (ex.response.status === 400) {
          this.$router.push({ name: "settings" });
        }
      });
    },
    closePopup() {
      this.weekOrMonthOrDay = "";
      this.activePlan = {};
      this.paymentSchedules = [];
      this.totalCustomerPayable = "";
      this.merchantAmount = "";
    },
    //* functions for plan configuration popup start here
    async openPlanConfigurationPopup(plan) {
      this.activePlan = plan.planId;
      this.popupActivo = true;

      await this.calculateOnPayLater({ planId: plan.planId._id }).then((result) => {
        const total = result.data.data.find(item => item.type == "Total");
        const disbursement = result.data.data.find(item => item.type == "Disbursement");
        const schedule = result.data.data.filter(item => item.type == "Payment");

        if (this.activePlan.productType == "B2C_BNPL") {
          this.payLaterSchedule = schedule.map((item) => {
            return {
              label: item.amount,
              value: new Date(item.date),
            };
          });
          this.paymentSchedules = this.payLaterSchedule;
          this.totalNumberOfPayments = this.payLaterSchedule.length;
        }

        this.totalCustomerPayable = total.amount;
        this.merchantAmount = disbursement.amount;
      });

      //for finding week month or day
      if (this.activePlan.paymentFrequency) {
        switch (this.activePlan.paymentFrequency) {
          case "DAILY":
            this.weekOrMonthOrDay = "day";
            break;

          case "FORTNIGHTLY":
            this.everyNumber = "2";
            this.weekOrMonthOrDay = "weeks";
            break;

          case "MONTHLY":
            this.weekOrMonthOrDay = "month";
            break;

          case "WEEKLY":
            this.weekOrMonthOrDay = "week";
            break;
        }
      }
    },

    async updateDisplayName() {
      this.$vs.loading();

      const payload = {
        id: this.user._id,
        data: this.products,
      };

      await this.updatePartnerPlans(payload).then((response) => {
        this.$vs.loading.close();
        this.$vs.notify({
          title: "Products",
          text: "Products updated",
          iconPack: "feather",
          icon: "icon-alert-circle",
          color: "success",
        });
        this.isSaved = true;
      }).catch((ex) => {
        this.$vs.loading.close();
      });
    },
    checkForm() {
      this.checkFormDirty = true;
    },
    async checkAdminLogin() {
      await this.checkLogin();
    },
  },
  beforeRouteLeave(to, from, next) {
    if ((this.isFormDirty || this.checkFormDirty) && !this.isSaved) {
      this.nextObj = next;
      this.popupActive = true;
      localStorage.setItem("redirectTo", to.name || undefined);
    } else {
      next();
    }
  },
  watch: {
    isFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    isSaved(latest, previous) {
      if (!latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    },

    checkFormDirty(latest, previous) {
      if (latest) {
        localStorage.setItem("formFieldUpdated", "yes");
      }
    }
  },
  mounted() {
    this.getAllMerchantProducts();
    this.checkAdminLogin();
  },
};
</script>

